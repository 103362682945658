export function getFoodType(size, age) {
    const types = {
      xsmall: [
        { minAge: 0, maxAge: 1, type: "X-small puppy" },
        { minAge: 2, maxAge: Infinity, type: "X-small adult" }
      ],
      small: [
        { minAge: 0, maxAge: 1, type: "Mini puppy" },
        { minAge: 2, maxAge: 7, type: "Mini adult" },
        { minAge: 8, maxAge: 11, type: "Mini adult 8+" },
        { minAge: 12, maxAge: Infinity, type: "Mini adult 12+" }
      ],
      medium: [
        { minAge: 0, maxAge: 1, type: "Medium puppy" },
        { minAge: 2, maxAge: 7, type: "Medium adult" },
        { minAge: 8, maxAge: 11, type: "Medium adult 7+" },
        { minAge: 12, maxAge: Infinity, type: "Medium adult 10+" }
      ],
      large: [
        { minAge: 0, maxAge: 1, type: "Maxi puppy" },
        { minAge: 2, maxAge: 5, type: "Maxi adult" },
        { minAge: 6, maxAge: 8, type: "Maxi adult 5+" },
        { minAge: 9, maxAge: Infinity, type: "Maxi adult 8+" }
      ],
      giant: [
        { minAge: 0, maxAge: 1, type: "Giant puppy" },
        { minAge: 2, maxAge: Infinity, type: "Giant adult" }
      ]
    }[size];
    for (const range of types) {
      if (age >= range.minAge && age <= range.maxAge) {
        return range.type;
      }
    }
    return;
  }
  
  export function getDailyRation(weight, activity, foodType) {
    const rationData = {
      "X-small adult": {
        1: { low: 24, medium: 27, high: 31 },
        1.5: { low: 32, medium: 37, high: 42 },
        2: { low: 40, medium: 46, high: 52 },
        2.5: { low: 47, medium: 54, high: 62 },
        3: { low: 54, medium: 62, high: 71 },
        3.5: { low: 60, medium: 70, high: 79 },
        4: { low: 67, medium: 77, high: 88 }
      },
      "Mini adult 8+": {
        2: { low: 40, medium: 47, high: 53 },
        3: { low: 55, medium: 63, high: 72 },
        4: { low: 68, medium: 78, high: 89 },
        5: { low: 80, medium: 93, high: 105 },
        6: { low: 92, medium: 106, high: 121 },
        7: { low: 103, medium: 119, high: 136 },
        8: { low: 114, medium: 132, high: 150 },
        9: { low: 124, medium: 144, high: 164 },
        10: { low: 135, medium: 156, high: 177 }
      },
      "Mini adult 12+": {
        2: { low: 42, medium: 48, high: 55 },
        3: { low: 57, medium: 65, high: 74 },
        4: { low: 70, medium: 81, high: 92 },
        5: { low: 83, medium: 96, high: 109 },
        6: { low: 95, medium: 110, high: 125 },
        7: { low: 107, medium: 124, high: 140 },
        8: { low: 118, medium: 137, high: 155 },
        9: { low: 129, medium: 149, high: 170 },
        10: { low: 139, medium: 162, high: 184 }
      },
      "Mini adult": {
        2: { low: 41, medium: 47, high: 54 },
        3: { low: 55, medium: 64, high: 73 },
        4: { low: 69, medium: 79, high: 90 },
        5: { low: 81, medium: 94, high: 107 },
        6: { low: 93, medium: 108, high: 122 },
        7: { low: 104, medium: 121, high: 137 },
        8: { low: 115, medium: 134, high: 152 },
        9: { low: 126, medium: 146, high: 166 },
        10: { low: 136, medium: 158, high: 179 }
      },
      "Medium adult 7+": {
        11: { low: 149, medium: 172, high: 192 },
        12: { low: 159, medium: 184, high: 209 },
        14: { low: 178, medium: 207, high: 235 },
        16: { low: 197, medium: 228, high: 259 },
        18: { low: 215, medium: 249, high: 283 },
        20: { low: 233, medium: 270, high: 307 },
        22: { low: 250, medium: 290, high: 329 },
        24: { low: 267, medium: 309, high: 352 },
        25: { low: 276, medium: 319, high: 363 }
      },
      "Medium adult 10+": {
        11: { low: 149, medium: 172, high: 195 },
        12: { low: 159, medium: 184, high: 209 },
        14: { low: 178, medium: 206, high: 234 },
        16: { low: 197, medium: 228, high: 259 },
        18: { low: 215, medium: 249, high: 283 },
        20: { low: 233, medium: 269, high: 306 },
        22: { low: 250, medium: 289, high: 329 },
        24: { low: 267, medium: 309, high: 351 },
        25: { low: 275, medium: 318, high: 362 }
      },
      "Medium adult": {
        11: { low: 149, medium: 173, high: 196 },
        12: { low: 159, medium: 185, high: 210 },
        14: { low: 179, medium: 207, high: 235 },
        16: { low: 196, medium: 229, high: 260 },
        18: { low: 216, medium: 250, high: 284 },
        20: { low: 234, medium: 271, high: 308 },
        22: { low: 251, medium: 291, high: 330 },
        24: { low: 268, medium: 310, high: 353 },
        25: { low: 276, medium: 320, high: 364 }
      },
      "Maxi adult 8+": {
        26: { low: 282, medium: 326, high: 371 },
        28: { low: 298, medium: 345, high: 392 },
        30: { low: 314, medium: 363, high: 473 },
        32: { low: 329, medium: 381, high: 433 },
        34: { low: 345, medium: 399, high: 453 },
        36: { low: 360, medium: 417, high: 473 },
        38: { low: 375, medium: 434, high: 493 },
        40: { low: 389, medium: 451, high: 512 },
        42: { low: 404, medium: 468, high: 531 },
        44: { low: 418, medium: 484, high: 550 }
      },
      "Maxi adult 5+": {
        26: { low: 282, medium: 327, high: 372 },
        28: { low: 298, medium: 346, high: 393 },
        30: { low: 314, medium: 364, high: 414 },
        32: { low: 330, medium: 382, high: 434 },
        34: { low: 345, medium: 400, high: 454 },
        36: { low: 360, medium: 417, high: 474 },
        38: { low: 375, medium: 435, high: 494 },
        40: { low: 390, medium: 452, high: 513 },
        42: { low: 405, medium: 468, high: 532 },
        44: { low: 418, medium: 485, high: 551 }
      },
      "Maxi adult": {
        26: { low: 275, medium: 318, high: 362 },
        28: { low: 291, medium: 337, high: 382 },
        30: { low: 306, medium: 354, high: 403 },
        32: { low: 321, medium: 372, high: 423 },
        34: { low: 336, medium: 389, high: 442 },
        36: { low: 351, medium: 406, high: 462 },
        38: { low: 365, medium: 423, high: 481 },
        40: { low: 380, medium: 440, high: 500 },
        42: { low: 394, medium: 456, high: 518 },
        44: { low: 408, medium: 472, high: 537 }
      },
      "Giant adult": {
        45: { low: 403, medium: 467, high: 531 },
        55: { low: 461, medium: 534, high: 607 },
        65: { low: 523, medium: 605, high: 688 },
        75: { low: 582, medium: 674, high: 766 },
        85: { low: 639, medium: 740, high: 841 },
        100: { low: 722, medium: 836, high: 950 }
      }
    };
  
    const findClosestWeight = (weight, weightsArray) => {
      return weightsArray.reduce((prev, curr) =>
        Math.abs(curr - weight) < Math.abs(prev - weight) ? curr : prev
      );
    };
  
    const foodData = rationData[foodType];
    if (!foodData) return;
  
    const weights = Object.keys(foodData).map(Number); 
    const closestWeight = findClosestWeight(weight, weights); 
  
    return foodData[closestWeight][activity]; 
  }  
  
  export function getMonthlyCost(weight, activity, foodType) {
    const prices = {
      "X-small puppy": { 1: undefined, 3: undefined, 7: undefined, 15: undefined },
      "X-small adult": { 1: 12072, 3: undefined, 7: undefined, 15: undefined },
      "Mini puppy": { 1: undefined, 3: undefined, 7: undefined, 15: undefined },
      "Mini adult": { 1: 10260, 3: 29820, 7: 68100, 15: undefined },
      "Mini adult 8+": { 1: 11460, 3: 31860, 7: undefined, 15: undefined },
      "Mini adult 12+": { 1: 11460, 3: 31860, 7: undefined, 15: undefined },
      "Medium puppy": { 1: undefined, 3: undefined, 7: undefined, 15: undefined },
      "Medium adult": { 1: undefined, 3: 27840, 7: 59388, 15: 106020 },
      "Medium adult 7+": { 1: undefined, 3: undefined, 7: undefined, 15: 130188 },
      "Medium adult 10+": { 1: undefined, 3: undefined, 7: undefined, 15: 130188 },
      "Maxi puppy": { 1: undefined, 3: undefined, 7: undefined, 15: undefined },
      "Maxi adult": { 1: undefined, 3: 28500, 7: undefined, 15: 105720 },
      "Maxi adult 5+": { 1: undefined, 3: undefined, 7: undefined, 15: 115620 },
      "Maxi adult 8+": { 1: undefined, 3: undefined, 7: undefined, 15: 115620 },
      "Giant puppy": { 1: undefined, 3: undefined, 7: undefined, 15: undefined },
      "Giant adult": { 1: undefined, 3: undefined, 7: undefined, 15: 91920 }
   };

    const dailyRation = getDailyRation(weight, activity, foodType);
    const monthlyRation = dailyRation * 30;
    const bagSizesInKg = [1, 3, 7, 15]; 
    const bagSizesInGr = bagSizesInKg.map(size => size * 1000);
  
    for (let i = 0; i < bagSizesInGr.length; i++) {
      const bagSize = bagSizesInKg[i];
      const bagPrice = prices[foodType][bagSize];
  
      if (bagPrice !== undefined && monthlyRation <= bagSizesInGr[i]) {
        return Math.ceil(bagPrice / 500) * 500;
      }
    }
  
    return;

  }
  