import React, { useEffect } from 'react';
import {
  Box, TextField, FormControl, InputLabel, Select, MenuItem, FormControlLabel, Checkbox
} from '@mui/material';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';

function DogForm({ 
  dogName, setDogName, dogBirthDate, setDogBirthDate, dogAge, setDogAge, useApproximateAge, setUseApproximateAge, 
  dogSize, setDogSize, dogActivityLevel, setDogActivityLevel, dogWeight, setDogWeight 
}) {
  useEffect(() => {
    if (dogBirthDate) {
      const birthDate = dayjs(dogBirthDate);
      const age = dayjs().diff(birthDate, 'year');
      setDogAge(age);
    }
  }, [dogBirthDate, setDogAge]);

  const handleCheckboxChange = () => {
    setUseApproximateAge(prev => !prev);
    if (useApproximateAge) {
      setDogBirthDate(null);
    }
  };

  return (
    <Box>
      <TextField
        label="Nombre"
        fullWidth
        variant="outlined"
        margin="normal"
        value={dogName}
        onChange={(e) => setDogName(e.target.value)}
        required
      />
      {useApproximateAge ? (
        <TextField
          label="Edad aproximada (años)"
          fullWidth
          variant="outlined"
          margin="normal"
          type="number"
          value={dogAge}
          onChange={(e) => setDogAge(e.target.value)}
          inputProps={{ min: 0, max: 25 }}
          required
        />
      ) : (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            label="Fecha de nacimiento"
            sx={{ width: '100%' }}
            value={dogBirthDate}
            onChange={(newValue) => setDogBirthDate(newValue)}
            renderInput={(params) => <TextField {...params} fullWidth margin="normal" required />}
          />
        </LocalizationProvider>
      )}
      <FormControlLabel
        control={<Checkbox checked={!useApproximateAge} onChange={handleCheckboxChange} />}
        label="Conozco la fecha exacta de nacimiento"
        sx={{ mb: 2 }}
      />
      <TextField
        label="Peso actual (kg)"
        fullWidth
        variant="outlined"
        margin="normal"
        type="number"
        value={dogWeight}
        onChange={(e) => setDogWeight(e.target.value)}
        inputProps={{ min: 0 }}
        required
      />
      <FormControl fullWidth margin="normal" required>
        <InputLabel>Tamaño adulto esperado</InputLabel>
        <Select
          value={dogSize}
          onChange={(e) => setDogSize(e.target.value)}
          variant="outlined"
        >
          <MenuItem value="xsmall">Miniatura (1-4kg)</MenuItem>
          <MenuItem value="small">Pequeño (4-10kg)</MenuItem>
          <MenuItem value="medium">Mediano (11-25kg)</MenuItem>
          <MenuItem value="large">Grande (26-44kg)</MenuItem>
          <MenuItem value="giant">Gigante (+45kg)</MenuItem>
        </Select>
      </FormControl>
      <FormControl fullWidth margin="normal" required>
        <InputLabel>Nivel de actividad diaria</InputLabel>
        <Select
          value={dogActivityLevel}
          onChange={(e) => setDogActivityLevel(e.target.value)}
          variant="outlined"
        >
          <MenuItem value="low">Menos de 1 hora</MenuItem>
          <MenuItem value="medium">Menos de 2 horas</MenuItem>
          <MenuItem value="high">Más de 3 horas</MenuItem>
        </Select>
      </FormControl>
    </Box>
  );
}

export default DogForm;
