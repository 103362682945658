import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Stepper,
  Step,
  StepLabel,
  Card,
  CardContent,
  Typography,
  Container,
  CircularProgress
} from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import DogForm from './DogForm';
import SubscriptionPreview from './SubscriptionPreview';
import ContactInfoForm from './ContactInfoForm';
import Header from './../Header';
import { getFoodType, getMonthlyCost } from './utils/subscriptionCalculations';
import ReactGA from 'react-ga4';  

function SubscriptionForm() {
  const [activeStep, setActiveStep] = useState(0);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [dogName, setDogName] = useState('');
  const [dogBirthDate, setDogBirthDate] = useState(null);
  const [dogAge, setDogAge] = useState('');
  const [useApproximateAge, setUseApproximateAge] = useState(true); 
  const [dogWeight, setDogWeight] = useState('');
  const [dogSize, setDogSize] = useState('');
  const [dogActivityLevel, setDogActivityLevel] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');

  const steps = ['Datos de tu perro', 'Confirmación'];

  const isValidEmail = (email) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  const isValidPhone = (phone) => /^\d{10}$/.test(phone);

  const isStep0Valid = () => 
    dogName &&
    ((useApproximateAge && dogAge >= 0 && dogAge <= 25) || (!useApproximateAge && dogBirthDate)) &&
    dogWeight &&
    dogSize &&
    dogActivityLevel;

  const isStep1Valid = () => 
    isValidEmail(email) && isValidPhone(phone);

  const handleNext = () => {
    ReactGA.event({
      category: 'SubscriptionForm',
      action: 'StepAdvanced'
    });
    setActiveStep((prev) => prev + 1)
  };
  const handleBack = () => setActiveStep((prev) => prev - 1);

  const handleSubmit = async () => {
    setLoading(true);
    try {
      const { day, month, year } = calculateDogBirthDate();
      const foodType = getFoodType(dogSize, dogAge);
      const monthlyCost = getMonthlyCost(dogWeight, dogActivityLevel, foodType);
      const formData = {
        dog_name: dogName,
        dog_weight: dogWeight,
        dog_size: dogSize,
        dog_activity_level: dogActivityLevel,
        dog_birth_day: day,
        dog_birth_month: month,
        dog_birth_year: year,
        dog_age: dogAge,
        dog_food_type: foodType,
        user_email: email,
        user_phone: phone,
        monthly_cost: monthlyCost
      };
      console.log(JSON.stringify(formData));
      const response = await fetch(
        `https://script.google.com/macros/s/AKfycbwgeTtxCbVyosHYtInq3ZirUNupaRi7nfh3xP-xpB2WruWDGWsF6CCl81Ppp3rdsV73/exec`,
        {
          method: "POST",
          body: JSON.stringify(formData),
        }
      );
      if (!response.ok) throw new Error("Network response was not ok");
      const data = await response.json();
      if (data.status !== "success") throw new Error("API error");

      ReactGA.event({
        category: 'SubscriptionForm',
        action: 'Submited'
      });

      setSuccess(true);
    } catch (error) {
      console.error('API call failed:', error);
    } finally {
      setLoading(false);
    }
  };

  const calculateDogBirthDate = () => {
    let day, month, year;
    if (dogBirthDate) {
      const date = new Date(dogBirthDate);
      day = date.getDate();
      month = date.getMonth() + 1;
      year = date.getFullYear();
    } else {
      year = new Date().getFullYear() - dogAge;
    }
    return { day, month, year };
  };

  const handleBirthDateChange = (date) => {
    setDogBirthDate(date);
  };

  const handleAgeChange = (age) => {
    setDogAge(age);
  };

  useEffect(() => {
    ReactGA.send({ hitType: 'pageview', page: 'subscription-form' });

    ReactGA.event({
      category: 'SubscriptionForm',
      action: 'Loaded'
    });

  }, []);

  useEffect(() => {
    if (dogBirthDate) {
      const date = new Date(dogBirthDate);
      const currentYear = new Date().getFullYear();
      setDogAge(currentYear - date.getFullYear());
    }
  }, [dogBirthDate]);

  const renderStepContent = () => {
    switch (activeStep) {
      case 0:
        return (
          <>
            <Typography variant="body1" sx={{ mb: 3 }}>
              ¡Cuéntanos un poco sobre tu perro! Queremos conocerlo bien para recomendarle el mejor alimento.
            </Typography>
            <DogForm 
              dogName={dogName} 
              setDogName={setDogName} 
              dogBirthDate={dogBirthDate} 
              setDogBirthDate={handleBirthDateChange} 
              dogAge={dogAge} 
              setDogAge={handleAgeChange} 
              useApproximateAge={useApproximateAge} 
              setUseApproximateAge={setUseApproximateAge} 
              dogSize={dogSize} 
              setDogSize={setDogSize} 
              dogActivityLevel={dogActivityLevel} 
              setDogActivityLevel={setDogActivityLevel} 
              dogWeight={dogWeight} 
              setDogWeight={setDogWeight} 
            />
          </>
        );
      case 1:
        return (
          <>
            <Typography variant="body1" sx={{ mb: 2 }}>
              ¡Ya casi! Comparte tus datos para que podamos coordinar los detalles de tu suscripción y darte la bienvenida a la manada Guau.
            </Typography>
            <SubscriptionPreview 
              dogAge={dogAge} 
              dogWeight={dogWeight} 
              dogSize={dogSize} 
              dogActivityLevel={dogActivityLevel} 
            />
            <ContactInfoForm 
              phone={phone} 
              setPhone={setPhone} 
              email={email} 
              setEmail={setEmail} 
              isValidPhone={isValidPhone} 
              isValidEmail={isValidEmail} 
            />
          </>
        );
      default:
        return null;
    }
  };

  return (
    <Box>
      <Header />
      <Container maxWidth="sm">
        <Card sx={{ mt: 4, p: 3, borderRadius: 2, boxShadow: 3 }}>
          <CardContent>
            {success ? (
              <Box 
                sx={{ 
                  backgroundColor: 'rgba(255, 255, 255, 0.9)',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                  p: 3,
                }}
              >
                <CheckCircleIcon color="success" sx={{ fontSize: 60 }} />
                <Typography variant="h5" sx={{ mt: 2, textAlign: 'center' }}>
                  ¡Gracias!
                </Typography>
                <Typography variant="body1" sx={{ mt: 1, textAlign: 'center' }}>
                  El equipo de Guau se pondrá en contacto en los próximos días para finalizar tu suscripción.
                </Typography>
              </Box>
            ) : (
              <>
                <Stepper activeStep={activeStep} sx={{ mb: 4 }}>
                  {steps.map((label) => (
                    <Step key={label}>
                      <StepLabel>{label}</StepLabel>
                    </Step>
                  ))}
                </Stepper>
                <Box>
                  {renderStepContent()}
                  <Box display="flex" justifyContent="space-between" sx={{ mt: 4 }}>
                    {activeStep > 0 && (
                      <Button 
                        disabled={loading} 
                        variant="outlined" 
                        color="primary" 
                        onClick={handleBack} 
                        sx={{ flex: 1, mr: 1 }}
                      >
                        {loading ? <CircularProgress size={24} /> : 'Volver'}
                      </Button>
                    )}
                    <Button 
                      variant="contained" 
                      color="primary" 
                      onClick={activeStep === steps.length - 1 ? handleSubmit : handleNext}
                      disabled={loading || (activeStep === 0 && !isStep0Valid()) || (activeStep === 1 && !isStep1Valid())}
                      sx={{ flex: 1, ml: 1 }}
                    >
                      {loading ? <CircularProgress size={24} /> : activeStep === steps.length - 1 ? 'Enviar' : 'Siguiente'}
                    </Button>
                  </Box>
                </Box>
              </>
            )}
          </CardContent>
        </Card>
      </Container>
    </Box>
  );
}

export default SubscriptionForm;
