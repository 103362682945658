import React, { useEffect } from 'react';
import { Button, Grid, styled } from '@mui/material';
import Header from './../Header';
import HeroContent from './HeroContent';
import ContactButton from './ContactButton';
import ReactGA from 'react-ga4';

const BackgroundImage = styled('img')(({ theme }) => ({
  position: 'absolute',
  bottom: 0,
  right: 0,
  height: '60%',
  objectFit: 'cover',
  opacity: 0.8,
  zIndex: 1,
  filter: 'blur(6px)',
  [theme.breakpoints.up('md')]: {
    filter: 'blur(0px)', 
    height: '80%', 
  },
}));

function LandingPage() {
  useEffect(() => {
    ReactGA.send({ hitType: 'pageview', page: 'landing' });

    ReactGA.event({
      category: 'Landing',
      action: 'Loaded',
    });
  }, []);

  return (
    <Grid 
      container 
      direction="column" 
      justifyContent="center"
      alignContent="center"
      alignItems="center"
      sx={{ 
        minHeight: '100vh', 
        background: 'linear-gradient(to bottom, #1d8667, #4bb9ec)',
        overflow: 'hidden',
        position: 'relative',
      }}
    >
      <Header />

      <Grid
        container
        maxWidth="md"
        sx={{
          position: 'relative',
          color: 'white',
          p: 3,
          flex: 1,
        }}
      >

        <HeroContent
          title="¿Tu perro se suele quedar sin alimento? ¿Te olvidas de comprárselo?"
          body={[
            "Con nuestra suscripción, te garantizamos que tu perro recibirá el mejor alimento, siempre a tiempo y sin que tengas que preocuparte por nada.",
            "Así de fácil funciona Guau:",
            "1. Suscribite: Nos dejas la información de tu mascota para que te hagamos una recomendación personalizada.",
            "2. Pagá: Validá los datos y realizá el pago.",
            "3. Despreocupate: Nosotros nos encargamos de que nunca le falte su comida, con entregas programadas a tu puerta.",
          ]}
          footer="En Guau, transformamos el cuidado de los perros en una experiencia simple y placentera."
        />

        <Grid container justifyContent="center">
          <Grid item xs={12} md={6} sx={{ zIndex: 2 }}>
            <ContactButton>Conocer más</ContactButton>
          </Grid>
        </Grid>

        <BackgroundImage
          src="background.png"
          alt="Background"
        />

      </Grid>

    </Grid>
  );
}

export default LandingPage;
