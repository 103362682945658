import React from 'react';
import { Box, Typography, Grid } from '@mui/material';

const Header = () => {
    return (
        <Box
        sx={{
        py: 4,
        width:'100%',
        textAlign: 'center',
        backgroundColor:'white'
        }}
        >
            <Grid container direction="column" alignItems="center">
                <Grid item>
                    <Box display="flex" alignItems="center" justifyContent="center">
                    <Box
                        component="img"
                        src="/logo192.png"
                        alt="Guau Logo"
                        sx={{
                        width: 50,
                        height:50,
                        marginRight: 1,
                        }}
                    />
                    <Typography variant="h4" gutterBottom>
                        Guau
                    </Typography>
                    </Box>
                </Grid>
                <Grid item>
                    <Typography variant="h5" gutterBottom>
                        Tranquilidad para vos, bienestar para tu perro
                    </Typography>
                </Grid>
            </Grid>
        </Box>
    )
}

export default Header;
