import React, { useEffect, useState } from 'react';
import { Grid, TextField, Typography } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

function ContactInfoForm({ email, setEmail,phone, setPhone, isValidPhone, isValidEmail }) {

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const phoneNumber = queryParams.get('phonenumber');
    if (phoneNumber) {
      setPhone(phoneNumber);
    }
  }, []);

  return (
    <>
      <Grid container alignItems="center" spacing={2}>
        <Grid item xs={10}>
          <TextField
            label="Teléfono"
            fullWidth
            variant="outlined"
            margin="normal"
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
            placeholder="1112345678"
            InputProps={{
              startAdornment: <Typography variant="body1" sx={{ color: 'text.secondary' }}>+54</Typography>,
            }}
            required
          />
        </Grid>
        <Grid item xs={2}>
          {isValidPhone(phone) ? <CheckCircleIcon color="success" /> :  <CheckCircleIcon color="disabled" />}
        </Grid>
      </Grid>
      <Grid container alignItems="center" spacing={2}>
        <Grid item xs={10}>
          <TextField
            label="Email"
            fullWidth
            variant="outlined"
            margin="normal"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </Grid>
        <Grid item xs={2}>
          {isValidEmail(email) ? <CheckCircleIcon color="success" /> :  <CheckCircleIcon color="disabled" />}
        </Grid>
      </Grid>
    </>
  );
}

export default ContactInfoForm;
