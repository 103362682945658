import React from 'react';
import { Grid, Typography } from '@mui/material';

const HeroContent = ({ title, body, footer }) => (
  <Grid container justifyContent="left" spacing={3} sx={{ zIndex: 2 }}>
    <Grid item md={8} xs={12}>
      {title && (
        <Typography variant="h5" sx={{ mb: 2}}>
          {title}
        </Typography>
      )}
      {body && body.map((line, index) => (
        <Typography
          key={index}
          variant="body1"
          paragraph
          sx={{ mb: 2}}
        >
          {line}
        </Typography>
      ))}
      {footer && (
        <Typography variant="h6" sx={{ mb: 2}}>
          {footer}
        </Typography>
      )}
    </Grid>
  </Grid>
);

export default HeroContent;
