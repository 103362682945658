import React, { useState, useEffect } from 'react';
import { Box, Typography, Card, CardContent, Divider, CircularProgress } from '@mui/material';
import {
  getFoodType,
  getDailyRation,
  getMonthlyCost
} from './utils/subscriptionCalculations';

function SubscriptionPreview({ dogAge, dogSize, dogActivityLevel, dogWeight }) {

  const [imageLoaded, setImageLoaded] = useState(false); 

  const foodType = getFoodType(dogSize, dogAge);
  const dailyRation = getDailyRation(dogWeight, dogActivityLevel, foodType);
  const cost = getMonthlyCost(dogWeight, dogActivityLevel, foodType);
  const formattedCost = cost ? cost.toLocaleString('es-ES') : undefined;

  const handleImageLoad = () => {
    setImageLoaded(true); 
  };

  return (
    <Box sx={{ maxWidth: 600, mx: 'auto', p: 2 }}>
      {!imageLoaded && (
        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
          <CircularProgress />
        </Box>
      )}
      
      <Card variant="outlined" sx={{ mb: 3 }} style={{ display: imageLoaded ? 'block' : 'none' }}>
        <CardContent>
          <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
            <img 
              src="/royalcaninlogo.png" 
              alt="Royal Canin Logo" 
              style={{ maxWidth: '100%', height: 'auto' }} 
              onLoad={handleImageLoad} 
            />
          </Box>
          <Divider sx={{ my: 2 }} />
          <Typography variant="h6">
            <strong>Producto:</strong> {foodType}
          </Typography>
          {(dailyRation !== undefined) && (
            <Typography variant="body1" sx={{ mb: 2 }}>
              <strong>Ración diaria recomendada: </strong> {dailyRation} gramos
            </Typography>
          )}
          {(cost !== undefined) && (
            <Typography variant="body1" sx={{ mb: 2 }}>
              <strong>Costo mensual estimado:</strong> ${formattedCost}
            </Typography>
          )}
        </CardContent>
      </Card>
    </Box>
  );
}

export default SubscriptionPreview;
